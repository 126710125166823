import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/sub/Scroll';
import React from 'react';
import logo from './assets/logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Services = lazy(() => import('./components/Services'));
const Resources = lazy(() => import('./components/Resources'));
const Case = lazy( () => import( './components/Case' ) );
const Blog = lazy(() => import('./components/Blog'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-[rgb(18,18,18,0.7)]">
              <LazyLoadImage
                src={logo}
                alt=""
                className="w-20 h-20 loader"
              />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route path="about-us" element={<About />} />
              <Route path="services/:id" element={<Services />} />
              <Route path="case-study/:id" element={<Case />} />
              <Route path="resources" element={<Resources />} />
              <Route path="/resources/:id" element={<Blog />} />
            </Route>
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
